import * as React from "react";
import {FC, useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
// @ts-ignore
import * as style from './index.module.scss';
import {connect, ConnectedProps} from "react-redux";
import {setBasketAmount} from '../../stores/basket/basketSlice';
import {Link} from "gatsby";
import {Client, handleApiError} from "../../services/ApiService";
import {isEmpty} from "gatsby/dist/schema/infer/inference-metadata";
import {RouteComponentProps} from "react-router";

const mapState = ({basket, auth}) => ({
    basket,
    auth
})

const mapDispatch = {
    setBasketAmount
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends RouteComponentProps {
}

const IndexPage: FC<PropsFromRedux & Props & any> = ({basket,setBasketAmount, auth}) => {

   useEffect(() => {
       // 215

       const queryString = window.location.search;
       const urlParams = new URLSearchParams(queryString);
       const orderID = urlParams.get('orderID')

       if(orderID) {
           Client.Auth.authGetNoID(auth.Key, 'Order/' + orderID + '/forGtag').then((res) => {

               //@ts-ignore
               if(window.dataLayer) {

                   let dataLayerPush = {
                       'cicData': {
                           'cic_transaction_id': res.data.OrderID,
                           'cic_order_number': res.data.OrderNumber ?? 'NOORDERNUMBER',
                           'cic_order_amount': res.data.OrderValue,
                           'cic_region': res.data.City ?? 'NOCITY',
                           'cic_country': res.data.Country ?? 'NOCOUNTRY',
                           'cic_plz': res.data.PLZ ?? 'NOPLZ',
                           'cic_customer_email': res.data.MemberEmail ?? 'NOEMAIL',
                           'cic_products': res.data.Products
                       },
                       'event': 'fs.checkoutSuccess'
                   };

                   let gme_checkoutSuccess = {
                       'event': 'purchase',
                       'data': {
                           'currency': 'EUR',
                           'transaction_id': res.data.OrderID,
                           'value': res.data.OrderValue,
                           'items': res.data.Products
                       }
                   }

                   // @ts-ignore
                   window.dataLayer.push({
                       'ts_checkout': {
                           'tsCheckoutOrderNr': res.data.OrderNumber ?? 'NOORDERNUMBER',
                           'tsCheckoutBuyerEmail': res.data.MemberEmail,
                           'tsCheckoutOrderAmount': res.data.OrderValue,
                           'tsCheckoutOrderCurrency': 'EUR',
                           'tsCheckoutOrderPaymentType': 'VORKASSE',
                       }});


                   // @ts-ignore
                   window.dataLayer.push(gme_checkoutSuccess);

                   // @ts-ignore
                   window.dataLayer.push(dataLayerPush);

                   console.log(window.dataLayer)
               }
           })
       }
   }, [window])

    useEffect(() => {
        setBasketAmount(0);
    }, [])

    return (
        <Layout>
            <div className={style.logoutHolder + ' text-center'}>
                <div className={style.background}/>
                <div className={"d-flex justify-content-center align-items-center flex-column"}>
                    <span> Danke für deine Bestellung! <br/> Du wirst schon bald von uns hören! &#127881;</span><br/>
                    <Link to={'/user'} className={style.toOrders}>
                        Meine Bestellungen ansehen
                    </Link>
                </div>


            </div>
        </Layout>
    )
}

export default connector(IndexPage);
